<template>
    <div class="tickets">
        <div v-if="!firstLoading">
            <template
                v-for="(ticket, index) in tickets"
                class="tickets_card"
            >
                <support-card
                    :key="index"
                    :ticket="ticket"
                    :index="index"
                />
            </template>
            <custom-button
                v-if="showLoadmode"
                :loading="loading"
                default
                class="btn-base_colored sort-button btn-page-location"
                @on-btn-click="loadMore"
            >
                Load more
            </custom-button>
        </div>
        <radius-loader v-else />

        <NoResult
            v-if="!total && !loading"
            type="tickets"
        />
    </div>
</template>

<script>
// Helpers
import { validationMixin } from 'vuelidate'
import { validationMessage } from 'vuelidate-messages'
import { required } from 'vuelidate/lib/validators'

// Mixins
import filtersMixin from '@/mixins/filtersMixin.js'

// Validation
import { formMessages } from '@/validation/support/Messages'

// Components
import SupportCard from '@/components/account/Cards/SupportCard/SupportCard';
import RadiusLoader from '@/components/common/RadiusLoader'

// vuex bindings
import { mapSupportFields } from '@/store/modules/support';

import NoResult from '@/components/common/NoResult'

export default {
    name: 'SupportTicket',
    components: {
        SupportCard,
        NoResult,
        RadiusLoader
    },
    mixins: [
        filtersMixin,
        validationMixin
    ],
    props: {
        orderid: {
            type: String
        },
        tickets: {
            type: Array
        },
        total: {
            type: Number
        },
        showLoadmode: {
            type: Boolean,
            required: false,
            default: false
        },
        loading: {
            type: Boolean
        },
        firstLoading: {
            type: Boolean
        }
    },
    validations: {
        message: { required }
    },
    data() {
        return {
            dataLoading: false,
            loadingBtn: false,
            paramsCount: 0
        }
    },
    computed: {
        ...mapSupportFields([
            'form_data.message'
        ]),
        firstname() {
            return this.$store.state.client.client_data.firstname
        },
        lastname() {
            return this.$store.state.client.client_data.lastname
        }
    },
    methods: {
        validationMessage: validationMessage(formMessages),
        loadMore() {
            this.$emit('load-more')
        }
    }
}
</script>
