<template>
    <div class="filter_wrapper">
        <div class="filter-sidebar">
            <!--            <button-->
            <!--                class="filter-sidebar_unread btn-base"-->
            <!--                :class="activeStatus === 'UNREAD' ? 'btn-main' : 'btn-border'"-->
            <!--                @click="setStatus('UNREAD')"-->
            <!--            >-->
            <!--                Unread-->
            <!--            </button>-->
            <div
                class="filter-sidebar_btn"
                :class="{'filter-sidebar_btn-inactive' : selected}"
                @click.stop="onShowPanel(selected)"
            >
                <filter-icon />
                <div
                    v-if="counter"
                    class="filter-sidebar_btn__count"
                >
                    {{ counter }}
                </div>
            </div>
            <span
                v-if="counter"
                class="filter-sidebar_btn__reset"
                @click.stop="resetFilter(true, false)"
            >
                Reset
            </span>
        </div>
        <Transition name="slide-fade">
            <div
                v-if="showPanel"
                v-click-outside="hideSidePanel"
                class="filter_panel"
            >
                <header class="filter_panel__header">
                    <span>Filters</span>
                    <div class="filter_panel__header_btns">
                        <button
                            class="btn-base btn-border"
                            @click.stop="resetFilter(true, false)"
                        >
                            Reset
                        </button>
                        <button
                            class="btn-close"
                            @click="showPanel = false"
                        >
                            <CloseIcon />
                        </button>
                    </div>
                </header>
                <main class="filter_panel__body">
                    <div
                        class="filter_tab"
                        :class="{'filter_tab--active': searchBy === 'date' || searchBy == 'default'}"
                    >
                        <h3>
                            <form-radio
                                v-model="searchBy"
                                text="Search by date"
                                :is-checked="searchBy === 'date' || searchBy == 'default'"
                                name="searchBy"
                                data="date"
                            />
                        </h3>
                        <div

                            class="filter_panel__body_field"
                        >
                            <range-date-picker
                                :label="'Dates picker'"
                                :from="from"
                                :to="to"
                                @onDatesSelect="updateDates"
                            />
                        </div>
                    </div>

                    <div
                        class="filter_tab"
                        :class="{'filter_tab--active': searchBy === 'query'}"
                    >
                        <h3>
                            <form-radio
                                v-model="searchBy"
                                text="Search by query"
                                :is-checked="searchBy === 'query'"
                                name="searchBy"
                                data="query"
                            />
                        </h3>

                        <double-form-element
                            class="filter_panel__body_field"
                        >
                            <form-input
                                v-model="searchQuery"
                                :field.sync="searchQuery"
                                legend="Search query"
                                placeholder="Search for"
                                name="Search"
                                type="text"
                            />
                            <form-select
                                v-model="search_by"
                                :items="searchByFields"
                                :value-select="search_by"
                                name="search by"
                            />
                        </double-form-element>
                    </div>

                    <div
                        class="filter_tab filter_tab--active"
                    >
                        <h3>Per Page</h3>

                        <div class="filter_panel__body_field">
                            <form-select
                                v-model="per_page"
                                :field.sync="per_page"
                                :items="rowsPerPageList"
                                :value-select="per_page"
                                :label="'Per page'"
                                name="per page"
                            />
                        </div>
                    </div>

                    <p
                        v-if="error"
                        class="filter_panel__body_error"
                    >
                        {{ error }}
                    </p>
                </main>
                <footer class="filter_panel__footer">
                    <button
                        class="btn btn-base btn-main"
                        @click="filter"
                    >
                        search
                    </button>
                </footer>
            </div>
        </Transition>
    </div>
</template>

<script>
import CloseIcon from 'mdi-vue/Close.vue';
import ClickOutside from 'vue-click-outside'
import FilterIcon from '@/components/icons/Filter';
import RangeDatePicker from '@/components/common/form-elements/RangeDatePicker';

export default {
    components: {
        FilterIcon,
        CloseIcon,
        RangeDatePicker
    },
    directives: {
        ClickOutside
    },
    props: {
        searchObject: {
            type: Object,
            required: true
        },
        currentAction: {
            type: String
        },
        selected: {
            type: [String, null],
            default: ''
        }
    },
    data: () => ({
        activeStatus: '',
        showPanel: false,
        searchBy: 'date',
        searchQuery: '',
        search_by: '',
        from: '',
        to: '',
        per_page: 0,
        error: '',
        isCreated: true
    }),
    computed: {
        searchByFields() {
            return [
                {
                    value: 'subject',
                    text: 'Title'
                },
                {
                    value: 'ticketid',
                    text: 'Ticket ID'
                },
                {
                    value: 'orderid',
                    text: 'Order ID'
                }
            ]
        },
        counter() {
            let counter = 0
            if (this.searchQuery) {
                counter += 1
            }
            if (this.from) {
                counter += 1
            }
            return counter
        },
        rowsPerPageList() {
            return [
                {
                    value: 5,
                    text: '5'
                },
                {
                    value: 10,
                    text: '10'
                },
                {
                    value: 25,
                    text: '25'
                },
                {
                    value: 50,
                    text: '50'
                },
                {
                    value: 100,
                    text: '100'
                }
            ]
        }
    },
    watch: {
        searchBy() {
            this.resetFilter(false)
        }
    },
    async created() {
        this.searchBy = (this.currentAction === 'date' || this.currentAction === 'query') ? this.currentAction : 'date'
        this.activeStatus = this.searchObject.status || ''
        this.searchQuery = this.searchObject.search_for || ''
        this.search_by = this.searchObject.search_by || ''
        this.from = this.searchObject.from || ''
        this.to = this.searchObject.to || ''
        this.per_page = this.searchObject.per_page || 10
    },
    methods: {
        setStatus(status) {
            this.activeStatus = status
            this.resetFilter(false)
            this.$emit('filterByStatus', {
                status: this.activeStatus
            })
        },
        hideSidePanel(e) {
            if (e.target.className !== 'form-select__list-item') {
                this.showPanel = false
            }
        },
        updateDates({ from, to }) {
            this.from = from
            this.to = to
        },
        resetFilter(emit = true, isReset = false) {
            this.searchQuery = ''
            this.search_by = ''
            this.from = ''
            this.to = ''
            this.error = ''
            if (emit) {
                this.searchBy = 'default'
                this.filter(isReset)
            }
        },
        filter() {
            this.activeStatus = ''
            this.$emit('filterOrder', {
                searchBy: this.searchBy,
                search_for: this.searchQuery,
                search_by: this.search_by,
                from: this.from,
                to: this.to,
                per_page: this.per_page
            })
        },
        onShowPanel(preset) {
            if (preset) {
                this.$emit('presetCancel', null)
                this.showPanel = true
            } else {
                this.showPanel = !this.showPanel
            }
        }
    }
}
</script>

<style lang="scss">
    .filter_wrapper{
        position: relative;
    }
    .filter{
        box-shadow: 0 0 51px rgba(0,0,0,.07);
        border-bottom: 4px solid #f3f6fb;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-right: 20px;
        &-statuses{
            padding-bottom: 0;
            display: flex;
            overflow-x: scroll;
            overflow-y: hidden;
            -ms-overflow-style: none;
            scrollbar-width: none;
            &::-webkit-scrollbar {
                width: 0;
                height: 0;
            }
            &_item {
                display: flex;
                align-items: center;
                padding: 30px 20px;
                position: relative;
                white-space: nowrap;
                cursor: pointer;
                &:after {
                    content: '';
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    height: 4px;
                    background: rgb(255, 105, 12);
                    opacity: 0;
                    transition: .3 opacity;
                }
                &--active{
                    &:after{
                        opacity: 1;
                    }
                }
                &:last-child{
                    margin-right: 0;
                }
                &-counter{
                    margin-left: 10px;
                    padding: 5px;
                    font-weight: bold;
                }
            }
        }
        .counter{
            &-ALL {
                background: #f2f2f2;
                color: #000;
            }
            &-PAID {
                background: rgba(17, 97, 224, 0.1);
                color: #1161E0;
            }
            &-COMPLETE {
                background: rgba(89, 199, 77, 0.1);
                color: #59C74D;
            }
            &-PENDING{
                background: rgba(255, 105, 12, 0.1);
                color: #FF690C;
            }
            &-REVISION {
                background: rgba(136, 86, 242, 0.1);
                color: #8856F2;
            }
            &-UNPAID, &-TO_REFUND {
                background: rgba(212, 28, 28, 0.1);
                color: #D41C1C;
            }
        }
        &-sidebar{
            display: flex;
            align-items: center;
            @media all and (max-width: 1200px){
                right: 15px;
            }
            @media all and (max-width: 480px) {
                position: static;
                transform: translateY(0);
            }
            &_unread{
                padding: 18px 36px;
            }
            &_btn{
                display: flex;
                align-items: center;
                cursor: pointer;
                position: relative;
                svg {
                    width: 26px;
                    height: 26px;
                }
                &__count{
                    display: flex;
                    width: 20px;
                    height: 20px;
                    background: #ffeee4;
                    border-radius: 50%;
                    align-items: center;
                    justify-content: center;
                    color: #ef9c60;
                    font-size: 16px;
                    font-weight: 700;
                    position: absolute;
                    top: -10px;
                    right: -13px;
                }
                &__reset{
                    cursor: pointer;
                    margin-left: 20px;
                    color: #ef9c60;
                }
            }
        }
    }
    .filter_panel{
        background: #fff;
        position: fixed;
        z-index: 999;
        top: 0;
        bottom: 0;
        right: 0;
        min-width: 320px;
        width: 33%;
        padding: 0 20px;
        box-shadow: 0 3px 1px -2px rgb(0 0 0 / 20%), 0 2px 2px 0 rgb(0 0 0 / 14%), 0 1px 5px 0 rgb(0 0 0 / 12%);
        &__header{
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 10px 0;
            border-bottom: 1px solid rgba(0,0,0,.12);
            span {
                font-size: 22px;
                font-weight: bold;
            }
            .btn-close{
                background: transparent;
                border: none;
                margin-left: 30px;
            }
        }
        &__body{
            &_btns{
                display: flex;
                flex-direction: column;
                margin-bottom: 10px;
                p {
                    margin-right: 30px;
                    font-weight: bold;
                }
            }
            &_group {
                button {
                    background: $btn-border-background;
                    border: $btn-border-border;
                    color: $btn-border-color;
                    font-size: 16px;
                    padding: 15px 30px;
                    cursor: pointer;
                    &:last-child {
                        border-left: 0;
                    }
                    &.btn-group--active{
                        background: $btn-main-background-hover;
                    }
                }
            }
            &_field{
                max-width: 340px;
                width: 100%;
            }
            &_error{
                margin-top: 10px;
                color: $error-color;
            }
        }
        &__footer{
            padding: 10px 0;
            border-top: 1px solid rgba(0,0,0,.12);
        }
    }
    .filter_tab{
        margin-bottom: 20px;
        position: relative;
        border-top: 1px solid rgba(0,0,0,.12);
        padding-top: 15px;
        h3{
            margin-bottom: 20px;
        }
        &:after{
            content: "";
            position: absolute;
            top: 50px;
            left: 0;
            right: 0;
            bottom:-10px;
            background: rgba(255,255,255, 0.7);
        }
        &--active{
            &:after{
                display: none;
            }
        }
    }
    .mb-15{
        margin-bottom: 15px;
    }

    .filter-sidebar_btn-inactive svg path {
        stroke: #abb1bb;
    }
</style>
