<template>
    <div class="">
        <breadcrumbs
            :settings="settingsBreadcrumbs"
        >
            <template
                #rightSide
            >
                <div class="breadcrumbs-btns">
                    <TicketFilter
                        :search-object="search"
                        :selected="search.preset"
                        @filterOrder="onUpdateSearch"
                        @presetCancel="setPreset"
                    />
                    <router-link
                        class="btn-base btn-main"
                        :to="{ name: 'support_form' }"
                    >
                        Contact Manager
                    </router-link>
                </div>
            </template>
            <template #total>
                <span
                    class="bc_info_balance"
                >{{ counter }}</span>
            </template>
        </breadcrumbs>

        <div class="wrapper wrapper--filter">
            <PresetsFilter
                v-if="counter"
                :presets="presets"
                :selected="search.preset"
                :loading="isLoadingPresets"
                @setPreset="setPreset"
            />
            <PresetsFilterSelect
                v-if="counter"
                v-model="search.preset"
                :items="presets"
                :is-loading="isLoadingPresets"
                :total="counter"
                name="All presets"
                @input="setPreset"
            />
        </div>

        <div
            ref="list"
            class="wrapper wrapper--mobile"
        >
            <support-ticket
                :tickets="tickets"
                :total="counter"
                :show-loadmode="lastPage > search.page"
                :loading="isLoading"
                @load-more="loadMore"
            />
            <radius-loader v-if="isLoading && tickets.length === 0" />
        </div>
    </div>
</template>

<script>
import { removeEmptyParamsFromRequest } from '@/helpers/utils/index.js'

import { eventBus } from '@/helpers/event-bus';
import { scroller } from 'vue-scrollto/src/scrollTo'
import Breadcrumbs from '@/components/Breadcrumbs';
import SupportTicket from '@/components/account/orders/SupportTicket.vue'
import RadiusLoader from '@/components/common/RadiusLoader'
import TicketFilter from '@/components/support/TicketFilter'

import Api from '@/helpers/api/index.js';

import PresetsFilter from '@/components/common/PresetsFilter'
import PresetsFilterSelect from '@/components/common/PresetsFilterSelect'

export default {
    components: {
        Breadcrumbs,
        SupportTicket,
        RadiusLoader,
        TicketFilter,
        PresetsFilter,
        PresetsFilterSelect
    },
    metaInfo: {
        title: 'Help Center'
    },
    data() {
        return {
            tickets: [],
            presets: [],
            counter: null,
            search: {
                page: 1,
                per_page: 10
            },
            isLoading: true,
            isLoadingPresets: true
        }
    },
    computed: {
        settingsBreadcrumbs() {
            const { breadcrumbs } = this.$route.meta
            return {
                title: breadcrumbs.name,
                steps: [
                    {
                        title: 'Home',
                        url: '/'
                    },
                    {
                        title: 'Help Center',
                        url: '/cp/tickets'
                    }
                ],
                excerpt: breadcrumbs.excerpt,
                additionalClass: breadcrumbs.additionalClass
            }
        },
        lastPage() {
            return Math.ceil(this.counter / this.search.per_page)
        },
        queryObject() {
            const { query } = this.$route

            return {
                ...query,
                page: +this.$route.query.page || 1,
                per_page: +this.$route.query.per_page || 10
            }
        }
    },
    async created() {
        this.search = {
            ...this.search,
            ...this.queryObject,
            page: 1
        }
        await Promise.all([this.getPresets(), this.getTicketList()])
    },
    mounted() {
        if (this.search.search_by === 'ticketid' && this.search.search_for) {
            const scrollTo = scroller()
            scrollTo(this.$refs.list, 400, { offset: -120 })
        }
    },
    methods: {
        async getPresets() {
            try {
                this.isLoadingPresets = true
                const { data } = await Api.get('/api/support/auth/list-presets')
                this.presets = data
            } catch (error) {
                eventBus.$emit('showSnackBar', error, 'error')
            } finally {
                this.isLoadingPresets = false
            }
        },
        async getTicketList(withCout = true) {
            try {
                this.isLoading = true
                let requests = []
                if (this.search.preset) {
                    requests = [...requests, Api.get('/api/support/auth/list-by-preset', removeEmptyParamsFromRequest({ mode: 'list', ...this.search }))]
                    if (withCout) {
                        requests = [...requests, Api.get('/api/support/auth/list-by-preset', removeEmptyParamsFromRequest({ mode: 'count', ...this.search }))]
                    }
                } else {
                    requests = [...requests, Api.get('/api/support/auth/list-by-params', removeEmptyParamsFromRequest({ mode: 'list', ...this.search }))]
                    if (withCout) {
                        requests = [...requests, Api.get('/api/support/auth/list-by-params', removeEmptyParamsFromRequest({ mode: 'count', ...this.search }))]
                    }
                }
                const responses = await Promise.all(requests)
                const { data } = responses[0]
                if (responses[1]) {
                    const { data: totals } = responses[1]
                    this.counter = totals.count
                }
                this.tickets = [...this.tickets, ...data.data]
                if (JSON.stringify(this.search) !== JSON.stringify(this.queryObject)) {
                    this.$router.replace({ query: removeEmptyParamsFromRequest({ ...this.search }) })
                }
            } catch (error) {
                eventBus.$emit('showSnackBar', error, 'error')
            } finally {
                this.isLoading = false
            }
        },
        async setPreset(preset) {
            this.search = {
                ...this.search,
                preset,
                page: 1
            }
            this.tickets = []
            await this.getTicketList()
        },
        async onUpdateSearch(value) {
            this.search = { ...this.search, ...value, page: 1 }
            this.tickets = []
            await this.getTicketList()
        },
        async loadMore() {
            this.search.page += 1
            await this.getTicketList(false)
        }
    }
}
</script>

<style lang="scss">
    .breadcrumbs-btns{
        display: flex;
        align-items: center;
        .filter_wrapper{
            margin-right: 20px;
        }
    }
    .wrapper--filter{
        margin-bottom: 20px;
    }
</style>
